import Styled from "styled-components";

export const ButtonContainer = Styled.button`
background-color: var(--maindark);
    border: none;
    color: var(--mainlight);
    font-size: 0.8rem;
    width: 5rem;
   
    box-shadow: 0 0 9px rgb(233, 10, 233);
    &:hover{
      background: var(--maindark);
      color: var(--mainwhite);
    }
    &:focus {
      outline: none
    };

    
`