import React, { Component } from "react";

import Styled from "styled-components";
import donate from "../img/utilities_and_roadmap.png";


export default class Utilities extends Component {
  render() {
    return (
      <CardWrapper className="text-dark tok">
       <img src={donate} width={"100%"}/>
      </CardWrapper>
    );
  }
}

const CardWrapper = Styled.div`
background-image: url{donate};
.card{

  font-size:small;
  &:hover{
  transform: scale(1.04);
  box-shadow: 1px 0 8px #00C3FF96
  }
}
`;
