import React, { Component } from "react";
import twod from "../img/Mageta.jpg";

export default class Twod extends Component {
  render() {
    return (
      <div
        className="justify-content-center row pt-5"
        style={{ backgroundColor: "#310c2b" }}
      >
        <div className="col-7">
          <img src={twod} width={"50%"} />
        </div>
        <div className="col-4 align-self-center">
          <h1 className="fw-bolder">Coming soon</h1>
        </div>
      </div>
    );
  }
}
