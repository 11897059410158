import React, { Component } from "react";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import logo from "../logo.png";
import Styled from "styled-components";
import { ButtonContainer } from "./button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiscord, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";
import AudioPlayer from "./Audioplayer";
import X from "../img/x-social-media-logo-icon.png";
import ME from "../img/magic.png"

export default class Navbar extends Component {
  render() {
    return (

      <NavWrapper className="navbar sticky-top bg-pri bg-opacity-25 navbar-expand-sm navbar-dark px-sm-3">
        <Link to="/">
          <div>
            <img
              src={logo}
              alt="clown"
              className="navbar-brand text-center"
              style={{ width: "5.3rem" }}
            />
          </div>
          <div className="aud">

    <AudioPlayer className="pla" />
          </div>

        </Link>
       

        <div
          class="offcanvas offcanvas-start bg-whi w-75 d-lg-none"
          tabindex="-1"
          id="offcanvasExample"
          aria-labelledby="offcanvasExampleLabel"
        >
          <div class="offcanvas-header">
            <div className="col text-center fs-5">
              {" "}
              <div className="col">
                <img src={logo} width="50%" />
              </div>
              <div className="col">
                <h6
                  class="offcanvas-title fs-5"
                  id="offcanvasExampleLabel"
                  style={{ color: "white" }}
                >
                  Cl<span>🤡</span>wn<span>🤡</span>n on  S<span>🤡</span>lana
                </h6>
              </div>
            </div>

            <button
              type="button"
              class="btn-close text-reset"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <ul className="navbar-nav row ms-lg-auto text-center deco">
            <li className="nav-item ml-5 col">
              <Link to="/" className="nav-link fs-5">
                Home
              </Link>
            </li>

            {/* <li className="col">
              <Link to="/about" className="nav-link fs-5">
                About
              </Link>
            </li> */}
{/* 
            <li className="col">
              <Link to="/utilities" className="nav-link fs-5">
                Tokenomics
              </Link>
            </li> */}

            <li className="col">
              <Link to="/walletchecker" className="nav-link fs-5">
                WL Checker
              </Link>
            </li>
            <a href="https://magiceden.io/marketplace/clownnft" className="nav-link Me">
               <img src={
                ME
               } alt=""/>
              </a> 

            <li className="col">
             
            </li>
          </ul>
        </div>

        <div className="d-lg-block d-none justify-content-center ms-auto">
          <div className="col ">
            <ul className="navbar-nav deco row ">
              <li className="nav-item ml-5 col">
                <Link to="/" className="nav-link fs-5">
                  HOME
                </Link>
              </li>

              {/* <li className="col">
                <Link to="/about" className="nav-link">
                  ABOUT
                </Link>
              </li> */}

              {/* <li className="col">
                <Link to="/utilities" className="nav-link">
                  Tokenomics
                </Link>
              </li> */}

              <li className="col">
                <Link to="/walletchecker" className="nav-link fs-5">
                  WLChecker
                </Link>
              </li>

              <li className="col">
              {/* <a href="https://swap.clownonsolana.com" className="nav-link">
                <ButtonContainer className="rounded-pill ">
                  Swap
                </ButtonContainer>
              </a> */}
            </li>
            </ul>
          </div>
        </div>
        <div className="col-4 row ">
          {" "}
          <ul className=" row justify-content-end ms-auto navbar-nav">
            <li className="col-1 ms-auto">
              <a href="https://x.com/ClownonSolana?s=20 " target={"_blank"}>
              <img
              src={X}
              alt="Twitter"
              className="xxx navbar-brand text-center"
              style={{ width: "4.3rem" }}
            />
              </a>
            </li>
            {/* <li className="col-1 ms-auto">
              <a href="#" target={"_blank"}>
                <button className="rounded-pill">
                  <FontAwesomeIcon icon={faQuestion} />
                </button>
              </a>
            </li> */}
          
            <li className="col-5 ms-auto">
              {" "}
            
            </li>

            
          </ul>
          
        </div>
       
        <div>
        <button
          class="btn btn-secondary d-lg-none "
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasExample"
          aria-controls="offcanvasExample"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        </div>
        <div>
        
        </div>
      </NavWrapper>
     
      
    );
  }
}

const NavWrapper = Styled.nav`


.nav-link{
  color: var(--mainwhite) !important;
  font-size: 0.7rem;
  text-transform: capitalize
}

.cryp{
  box-shadow: 0 0 10px #914581;

}

.btnsetter{
  font-size:0.8rem; 
  width:5rem;

  &:hover{
    color: blue !important;
  }
}

`;
