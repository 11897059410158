import React, { Component } from 'react';
import Clown from "../../src/clown.mp3";

class AudioPlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMuted: false,
    };
  }

  toggleMute = () => {
    this.setState((prevState) => ({
      isMuted: !prevState.isMuted,
    }));
  };

  render() {
    return (
      <div>
        <audio
          loop
          autoPlay
          muted={this.state.isMuted}
        >
          <source src={Clown} type="audio/mp3" />
          Your browser does not support the audio element.
        </audio>
        <button className='aud' onClick={this.toggleMute}>
          {this.state.isMuted ? (
            <span role="img" aria-label="Unmute">🔊</span>
          ) : (
            <span role="img" aria-label="Mute">🔇</span>
          )}
        </button>
      </div>
    );
  }
}

export default AudioPlayer;
