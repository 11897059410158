import React, { Component } from "react";
import Styled from "styled-components";
import Dee from "../img/GIF-220509_231630.gif";

export default class Threed extends Component {
  render() {
    return (
      <DivWrapper className="pt-5">
        <div className="container pt-5 pb-5">
          <div className="container pt-5 pb-5">
            {" "}
            <div className="container pt-5 pb-5">
              {" "}
              <div className="container pt-5 pb-5">
                {" "}
                <div className="container pt-5 pb-5">
                  {" "}
                  <h1 className="fw-bolder text-center">Coming soon</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DivWrapper>
    );
  }
}

const DivWrapper = Styled.div`

background-image: linear-gradient(#00000070,#000000bd),url(${Dee});
background-repeat: no-repeat;
background-size: cover


`;
