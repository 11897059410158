import React, { Component } from "react";
import planet from "../img/38.JPG";
import tech from "../img/Story Line.jpg";
import Utilities from "./Utilities";
export default class About extends Component {
  render() {
    return (
      <div className="bg-deff text-white">
        <div className="row container pt-5 ml-auto">
          <div className="col-lg-5 ms-auto ">
            {" "}
            {/* <img
              src={planet}
              alt="planet"
              loading={"lazy"}
              className="rounded-circle"
              width={"100%"}
            /> */}
          </div>

          <Utilities/>
        </div>
        {/* <div className="bg-dark text-light mt-4">
          <div className=" container col-lg-7 pt-5">
            <h4 className="fw-bolder text-center">Story Line</h4>
            <img src={tech} width={"100%"} />
            <p>
              After years of being in the hood, They all grew up and went to
              different worlds to live their separate live and fulfill their
              dreams, but they never forgot where they came from, they never
              forgot the hood. Some were princess, some were knights, some were
              hoodlums, some were gangsters and some were sailors, but they
              never forgot where they came from. They are all from different
              cultures and backgrounds but they never forgot the people they
              left behind. They all came back to support the hood, because that
              is their roots. They are back to trouble the blockchain and
              discover their true self.
            </p>
          </div>
        </div> */}
        {/* <div className="bg-white text-black pt-4">
          <div className="container col-lg-8">
            <h4 className="fw-bolder text-center">Inspiration</h4>
            <p className="mt-5 pb-5">
              I grew up in the hood/trenches. I know how hard it is being in the
              hood. I've been there where I hardy have a square meal a day. I
              was bullied so many times when I was a kid, I was an introvert and
              I was picked on because I didn't know how to fight and when I try
              to speak they beat me up again. This kind of things push kids to
              cultism, rubbery, depression even suicide. I know how I started
              and how hard it was, so I'm going back to the hood. to help the
              poor and less fortunate kids. I'll be doing videos of me going to
              different communities of the less privilege and give them food
              stuff, I will play with them(kids) and show them that they don't
              need to do bad things to survive.{" "}
            </p>
          </div>
        </div> */}
        {/* <div className="row container ms-auto pb-5">
          {" "}
          <div className="col-5">
            <a href="https://cryptohoodnft.com/mint">
              <button className="rounded-pill justify-content-center bg-primary text-white border-none border fw-bolder">
                MINT HERE
              </button>
            </a>
          </div>

          <div className="col-5 ms-auto">
            <a href="hhttps://opensea.io/collection/crypto-hood-s1">
              <button className="rounded-pill justify-content-center bg-primary text-white border-none border fw-bolder">
                BUY ON OPENSEA
              </button>
            </a>
          </div>
        </div> */}
      </div>
    );
  }
}
