//import logo from './logo.svg';
import React, { Component } from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/free-solid-svg-icons";
import "font-awesome/css/font-awesome.min.css";
import Navbar from "./components/Navbar";
import About from "./components/About";
import Utilites from "./components/Utilities";
import SiteContent from "./components/SiteContent";
import Default from "./components/Default";
import Faq from "./components/Faq";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Twod from "./components/Twod";
import Threed from "./components/Threed";
import AudioPlayer from '../src/components/Audioplayer';
import WalletChecker from "./components/WalletChecker";
import Team from "./components/Team";

class App extends Component {
  render() {
    return (
      
      <React.Fragment>
        <Navbar />
        <Routes>
          <Route exact path="/" element={[<SiteContent />, <About />]} />
          <Route path ="/utilities" element={<Utilites/>}/>
          <Route path='/faq' element={<Faq/>}/>
          <Route path='/contact' element={<Contact/>}/>
          <Route path='/2d' element={<Twod/>}/>
          <Route path="/3d" element={<Threed/>}/>
          <Route path="/walletchecker" element={<WalletChecker/>}/>

          <Route path="*" element={<Default/>}/>
          </Routes>
        <Footer/>
      </React.Fragment>
      
    );
  }
}

export default App;
