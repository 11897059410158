import React, { useState } from 'react';
import clownImage from '../logo.png';
import '../WalletChecker.css';
import "bootstrap/dist/css/bootstrap.min.css";

function WalletChecker() {
  const [address, setAddress] = useState('');
  const [eligibilityMessage, setEligibilityMessage] = useState('');
  const [isClown, setIsClown] = useState(false);

  const checkWallet = async () => {
    // Remove spaces from the entered address
    const cleanedAddress = address.replace(/\s/g, '');

    setEligibilityMessage('waiting for server response...');

    try {
      const response = await fetch("https://server-n45v.onrender.com/check-wallet", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ address: cleanedAddress }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      setIsClown(result.isClown);
      setEligibilityMessage(result.message);
    } catch (error) {
      console.error('Error checking wallet:', error);
      setEligibilityMessage('Error checking wallet. Please try again.');
    }
  };

  return (
    <div className="wallet-checker-container">
      <h1>Airdrop eligibility</h1>
      <input
        type="text"
        placeholder="Enter Wallet Address"
        value={address}
        onChange={(e) => setAddress(e.target.value)}
      />
      <button onClick={checkWallet}>Check</button>
      {eligibilityMessage && <p className="eligibility-message">{eligibilityMessage}</p>}
      {isClown ? (
        <div className="clown-message">
          <img src={clownImage} alt="Clown" className="clown-image" />
        </div>
      ) : null}
      <div>
        <p>
       <sm style={{color:"red"}}> Madlad </sm>
       <sm style={{color:"red"}}>  Namaste</sm>  
       <sm style={{color:"red"}}> Theheist  </sm>
       <sm style={{color:"red"}}> Bodoggos </sm> 
       <sm style={{color:"red"}}> ABC  </sm> 
       <sm style={{color:"red"}}> Degenape  </sm> 
       <sm style={{color:"red"}}> Sharkyfi </sm> 
       <sm style={{color:"red"}}> Blocksmith</sm> 
       <sm style={{color:"red"}}> $Woof </sm> 
       <sm style={{color:"red"}}> $serum </sm>  <br/>
       <sm style={{color:"red"}}> $cheems </sm> 
       <sm style={{color:"red"}}> $Samo  </sm> 
       <sm style={{color:"red"}}> $Jitosol  </sm> 
       <sm style={{color:"red"}}> Degennews</sm>  
       <sm style={{color:"red"}}> SMB </sm>  
       <sm style={{color:"red"}}> Metame  </sm> 
<sm style={{color:"red"}}> $myro </sm> 
       <sm style={{color:"red"}}> Elixir ovols</sm> 
       <sm style={{color:"red"}}> AssetDash/Vanta</sm> 
       <sm style={{color:"red"}}> Aurory</sm>
<sm style={{color:"red"}}> $peep</sm> 
<sm style={{color:"red"}}> Cardboard citizens </sm> 
    
        </p>
      </div>
    </div>
    
  );
}

export default WalletChecker;
