import React, { Component } from "react";
import Styled from "styled-components";
import { Link } from "react-router-dom";
import { Carousel } from "3d-react-carousal";
import "bootstrap/dist/css/bootstrap.min.css";
//import { faEtsy } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGaugeHigh,
  faPlane,
  faUserGroup,
  faWallet,
} from "@fortawesome/free-solid-svg-icons";
import nft1 from "../img/IMG_2613.png";
import nft2 from "../img/IMG_2614.png";
import nft3 from "../img/IMG_2615.png";
import nft4 from "../img/IMG_2616.png";
import nft5 from "../img/IMG_2618.png";
import nft6 from "../img/IMG_2619.png";
import nft7 from "../img/IMG_2620.png";
import nft8 from "../img/IMG_2621.png";
import nft9 from "../img/IMG_2622.png";
import nft10 from "../img/IMG_2624.png";
import nft11 from "../img/IMG_2618.png";
import nft12 from "../img/IMG_2613.png";
import nft13 from "../img/IMG_2620.png";
import nft14 from "../img/IMG_2619.png";

import speed from "../img/nnft.png";
import easy from "../img/air.png";
import bill from "../img/stake.png";
import App from '../img/app.png';
import banner from  "../img/Ccc.jpg";
import { faSketch } from "@fortawesome/free-brands-svg-icons";
import { faImage } from "@fortawesome/free-regular-svg-icons";
import fff from "../img/C.png"
import Utilities from "./Utilities";
export default class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAirdropContent: false,
      showNFTContent: false,
      showStakingContent: false,
      showAppContent: false,
    };
    this.cardRefs = Array.from({ length: 4 }, () => React.createRef());
  }

  toggleAirdropContent = () => {
    this.setState((prevState) => ({
      showAirdropContent: !prevState.showAirdropContent,
    }));
  };

  toggleNFTContent = () => {
    this.setState((prevState) => ({
      showNFTContent: !prevState.showNFTContent,
    }));
  };

  toggleStakingContent = () => {
    this.setState((prevState) => ({
      showStakingContent: !prevState.showStakingContent,
    }));
  };

  toggleAppContent = () => {
    this.setState((prevState) => ({
      showAppContent: !prevState.showAppContent,
    }));
  };
  render() {
    let slides = [
      <img src={nft1} alt="1" width={"100%"} />,
      <img src={nft2} alt="2" width={"100%"} />,
      <img src={nft3} alt="3" width={"100%"} />,
      <img src={nft4} alt="4" width={"100%"} />,
      <img src={nft5} alt="5" width={"100%"} />,
      <img src={nft6} alt="6" width={"100%"} />,
      <img src={nft7} alt="7" width={"100%"} />,
      <img src={nft8} alt="8" width={"100%"} />,
    ];

    let slider = [
      <img src={nft9} loading="lazy" alt="1" width={"100%"} />,
      <img src={nft10} loading="lazy" alt="2" width={"100%"} />,
      <img src={nft11} loading="lazy" alt="3" width={"100%"} />,
      <img src={nft12} loading="lazy" alt="4" width={"100%"} />,
      <img src={nft13} loading="lazy" alt="5" width={"100%"} />,
      <img src={nft14} loading="lazy" alt="6" width={"100%"} />,
    ];
    return (
      <div>
        <div className="row">
          <ContentWrapper className="container text-center align-self-center mt-2 col-lg-4 ">
            <h1 className="fw-bolder"></h1>
            <img src={banner} alt="banner" loading="lazy" className="bann"/>
             
           <div>
            <h3>

            </h3>
           </div>
          </ContentWrapper>

          <CarouselWrapper className="col-10 col-sm-5  mt-5 container rounded-pill align-self-center mb-5">
            <Carousel
              slides={slides}
              autoplay={true}
              className="rounded"
              interval={1000}
            />
          </CarouselWrapper>
        </div>

        <AboutWrapper className="text-white fw-5 bg-diff  rounded-top mt-4">
          <div className="container justify-content-center">
            <div className="row">
              <div className="col-lg-5 justify-content-center align-self-center">
                <div>

                <h4 className=" pt-4 cll text-uppercase">clowns</h4>
                <p className="chj"> Clowns on Solana is a community of clowns 🤡 who called solana a shitcoin, but there’s more to it than meets the eye. Clowns on Solana will unlock the true potential of Solana and bring more clowns into the  ecosystem. 🚀</p>
                </div>
              
              </div>

              <div className="col-lg-4 col-4  ms-auto mt-9 ">
              <img
              src={fff}
              alt="clown"
              className="crown3"  
            />
             
               
              </div>
            </div> 
          </div>
        </AboutWrapper>

        <div className="bgg">
            <h4 className="ppp">Plans</h4>
            <div className="row ">
              <div className="col-lg-4">
                <div className="card m-4 caard">
                  <img src={easy} alt="easy to use" loading="lazy" />

                  <div className="card-body">
                  <h5
            className="card-title  add text-white"
            onClick={this.toggleAirdropContent}
            style={{ cursor: "pointer" }}
          >
            <span style={{ color: "red" }}>
              <FontAwesomeIcon icon={faPlane} />
            </span>{" "}
            Airdrop
          </h5>
          {this.state.showAirdropContent && (
            <h6 className="add text-white">
              There are going to be 4 batches of airdrop; Clown NFT holders
2500 random selected wallets from google form 
Airdrop to OG communities on solana
OG clown role in discord.
🪂 Airdrop allocation
Clown NFT holder - 3M $Clown each 
Google Form airdrop - 150,000 $Clown each 
OG communities - 100,000 $Clown each
OG role on discord- TBA
              
            </h6>
          )}
                   
                  </div>
                </div>
              </div>
              <div className="col-lg-4 ">
                <div className="card m-4">
                  <img src={speed} alt="fast" />
                  <div className="card-body">
                    <h5 className="card-title add text-white"
                    onClick={this.toggleNFTContent}
                    style={{ cursor: "pointer" }}>
                      {" "}
                      <FontAwesomeIcon icon={faImage} />Clown NFT 
                    </h5>
                    <div className="card-text">
                    {this.state.showNFTContent && (
                      <p className="add text-white">
                     
                     Reveal Date: 7days post mint 
🎈Airdrop to NFT holders (3M $CLOWN token each) 
📸 Snapshot and distribution of airdrop will be taken within few hours before token launch, as rewards extend beyond the NFT itself!
🌟 Staking Rewards Unveiling!
🎨NFT utilities
🤡 Get ready to join the CLOWN revolution!
                      </p>
                       )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="card m-4">
                  <img src={bill} alt="go" loading="lazy" />
                  <div className="card-body">
                    <h5 className="card-title add text-white"
                     onClick={this.toggleStakingContent}
                     style={{ cursor: "pointer" }}>
                      {" "}
                      <span style={{ color: "blueviolet" }}>
                        <FontAwesomeIcon icon={faWallet} />
                      </span>{" "}
                      Staking/yield
                    </h5>
                    <div className="card-text text-white">
                    {this.state.showStakingContent && (
                      <p className="add text-white">
                      There will be staking pools which will allow holders of Clown NFTS and clowns token holders stake to earn more rewards
                      Staking pool to $Clown, $Bonk and $Woof <br/>
                      <p className="rounded-pill" style={{
                        margin:'20px'
                      }}>
                      <a className="sss" href="https://anybodies.com/c/clown">
                      STAKE
                    </a>
                      </p>
                     
                      </p>
                      
                        )}
                          
                    </div>
                  
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card m-4">
                  <img src={App} alt="go" loading="lazy" />
                  <div className="card-body">
                    <h5 className="card-title add text-white" onClick={this.toggleAppContent}
            style={{ cursor: "pointer" }}>
                      {" "}
                      <span style={{ color: "blueviolet" }}>
                        <FontAwesomeIcon icon={faSketch} />
                      </span>{" "}
                      Clown mobile app
                    </h5>
                    <div className="card-text add text-white">
                    {this.state.showAppContent && (
                      <p className="">
                      Clown app launch: TBA <br/>
                      features <br/>
                     GO CL🤡WN YOURSELF AND FIND OUT
                      
                      </p>
    )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>

        <HowWrapper className="mt-1">
          <div className="container">
            <div className="row pt-5 pb-5 text-white">
              <div className="col-lg-6 align-self-center">
                <h4 className="solh">$Clown</h4>
                <div className="align-content-center">
                  <ul className="justify-content-center ssd">
                    <li className="m-2">
                    Token name: ClownOnSolana
                    </li>

                    <li className="m-2">
                    Token ticker: $Clown
                     
                    </li>

                    <li className="m-2">
                    Address: V7ntWk4D9Frwaec2CUjMRMMsJG28Cd31HxDKNdP1qjM
                      
                    </li>
                   <li className="m-2">Token supply :5,000,000,000  </li>
                   
                   <p className="solscan">
              <a href="https://solscan.io/token/V7ntWk4D9Frwaec2CUjMRMMsJG28Cd31HxDKNdP1qjM " target={"_blank"}>
               veiw on solscan
              </a>
            </p>
                  </ul>       
                </div>
              </div>
              <div className="col-lg-5">
              
                <Carousel
                  slides={slider}
                  autoplay={true}
                  className="rounded"
                  interval={200}
                />
              </div>
            </div>
          </div>
        </HowWrapper>
      </div>
    );
  }
}

const ContentWrapper = Styled.div`

color: white;
font-weight: bold;



border-radius: 50%;

.text-yellow{
  color: yellow;
  font-weight: bolder;
}

.set{
  color: #fb10fb;
  text-shadow: none;
  
}
.btnset{
  background-color: purple;
  color: var(--mainwhite);
  border: none;
  width: 30%;
}

`;
const CarouselWrapper = Styled.div`
box-shadow: 0 0 20px purple;
.set{
  box-shadow: 0 0 10px purple;
 }
 Carousel{
   box-shadow: 0 0 10px whit;
 }

`;

const AboutWrapper = Styled.div`
font-size: 12px;
.card{
&:hover {
transform: scale(1.1);
}
&:focus {
  transform: scale(1.1);
}
}
.card-body{
  font-family: san-serif;
  font-size: 13px;
}


`;

const HowWrapper = Styled.div`
font-size:16px;
font-family: Arial;
background-color: #333333;
.slider-left{
  border:none ;
  color:red

}
button{
  border: 1px solid white;
  padding: 1.5%;
  background-color: black;
  color:white;

&:hover{
background-color: white;
color: black;
}
}

`;