import React, { Component } from "react";
import { Link } from "react-router-dom";
import Styled from "styled-components";
import logo from "../logo.png";

export default class Footer extends Component {
  render() {
    return (
      <FooterWrapper className="pt-5 justify-content-center text-white">
        <div className="row">
          <div className="col-lg-4 text-start justify-content-start">
            <div className="w-25 justify-content-start">
              <img
                src={logo}
                alt="clown"
                className="navbar-brand text-center"
                style={{ width: "100%" }}
              />
            </div>
          </div>

          <BottomWrapper className="col-4 col-lg-3 mt-lg-5 text-start text-white">
            <ul className="navbar-nav row text-white deco d-flex">
              <li className="nav-item ml-5 col">
                <Link to="/" className="nav-link text-white">
                  Home
                </Link>
              </li>

              <li className="col">
                <Link to="/walletchecker" className="nav-link text-white">
                    WLchecker
                </Link>
              </li>
              <div className="col">
                <a href="https://x.com/ClownonSolana?s=20" className="text-start nav-link text-white">
                  Twitter
                </a>
              </div>
            </ul>
          </BottomWrapper>
        </div>
        <div className="col-lg-10 text-end text-white">
          <p style={{ fontSize: "small" }}>© Cl🤡wn 🤡n s🤡lana, 2023.</p>
        </div>
      </FooterWrapper>
    );
  }
}

const FooterWrapper = Styled.div`
  background-color: #333333;
  height: 302px !important;
`;

const BottomWrapper = Styled.div`
  .nav-link {
    color: var(--mainwhite) !important;
    font-size: small;
    text-transform: capitalize;
    font-family: Arial !important;
    font-size: bolder;
    background-color: #333333 !important;
  }

  .cryp {
    box-shadow: 0 0 10px #914581;
  }
`;
