import {
  faDiscord,
  faInstagram,
  faTiktok,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Styled from "styled-components";
import React, { Component } from "react";

export default class Contact extends Component {
  render() {
    return (
      <BottomWrapper
        className="bg-white text-black text-center 
      "
      >
        <h3>Socials</h3>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-2 rounded-pill m-4 nav-link border">
              <a>
                <FontAwesomeIcon icon={faTwitter} />
                Twitter
              </a>
            </div>

            <div className="col-lg-2 rounded-pill m-4 nav-link border">
              <a>
                <FontAwesomeIcon icon={faDiscord} />
                Discord
              </a>
            </div>

            <div className="col-lg-2 rounded-pill m-4 nav-link border">
              <a>
                <FontAwesomeIcon icon={faInstagram} />
                Instagram
              </a>
            </div>

            <div className="col-lg-2 rounded-pill m-4 nav-link border">
              <a>
                <FontAwesomeIcon icon={faTiktok} />
                Tiktok
              </a>
            </div>
          </div>
        </div>
      </BottomWrapper>
    );
  }
}
const BottomWrapper = Styled.div`
.nav-link{
  color: var(--maindark) !important;
 font-size:small;
  text-transform: capitalize;
&:hover{
  background-color:black;
  color:var(--mainwhite) !important;
}
}

.cryp{
  box-shadow: 0 0 10px #914581;

}

`;
